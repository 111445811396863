<template>
  <el-dialog title="审核记录" class="dialogTable" width="1300px" :close-on-click-modal="false" append-to-body
             :visible.sync="visible">
    <review-steps :stepsList="stepsList" :steps="steps" :disabled="disabled"></review-steps>
    <el-form v-if="pageName == 'resourceAuditList'" style="margin-top: 15px" size="small" :inline="true" ref="searchForm" :model="searchForm">
      <el-form-item prop="name" label="审核人">
        <el-select
            style="width: 100%;"
            v-model="searchForm.userId"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请选择审核人"
            :remote-method="remoteMethod"
            :loading="personnelLoading">
          <el-option
              v-for="item in applicantList"
              :key="item.userId"
              :label="item.name"
              :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search(1)" size="small">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="resetting()" size="small">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        v-loading="loading"
        height="200px"
        size="small"
        class="table" ref="multipleTable">
      <el-table-column type="index" label="序号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="appraisalState" label="审核结果" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ $dictUtils.getDictLabel("resource_state", scope.row.appraisalState, '') }}
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="审核人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="审核时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="refuseReason" label="审核意见" width="250px" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-show="!scope.row.editShow">{{ scope.row.refuseReason }}</div>
          <div v-show="scope.row.editShow">
            <el-input width="95%" size="small" maxlength="100" v-model.trim="scope.row.refuseReason"
                      clearable placeholder="请输入审核意见(限100字)"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="updateUserName" label="提交人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateTime" label="提交时间" show-overflow-tooltip></el-table-column>
      <el-table-column
          v-if="resourceState != 2 && pageName == 'resourceAuditList'"
          fixed="right"
          width="180"
          label="操作">
        <template slot-scope="scope">
          <div v-if="scope.row.editShow">
            <el-button type="text" size="small"
                       v-show="hasPermission('resourceAuditList:save')"
                       @click="saveData(scope.row)">保存
            </el-button>
            <el-button type="text" size="small"
                       @click="saveData(scope.row,1)">取消
            </el-button>
          </div>
          <div v-else>
            <el-button type="text" size="small"
                       v-show="hasPermission('resourceAuditList:edit')"
                       @click="edit(scope.row)">修改
            </el-button>
            <el-button type="text" size="small"
                       v-show="hasPermission('resourceAuditList:delete')"
                       @click="deleteR(scope.row)">删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="searchForm.current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="searchForm.size"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </div>
  </el-dialog>
</template>

<script>
import ReviewSteps from "@/views/modules/collection/accounts/module/reviewSteps.vue";

export default {
  components: {ReviewSteps},
  data() {
    return {
      visible: false,
      loading: false,
      searchForm: {
        size: 10,
        current: 1,
        resourseId: '',
        userId: '',
      },
      personnelLoading: false,
      personnelList: [],
      applicantList: [],
      dataList: [],
      total: 0,
      pageName: '',
      resourceState: '',

      revdBusinessId:'',
      steps:0,
      disabled:false,
      stepsList:[],
    }
  },
  methods: {
    init(id, pageName, resourceState,revdBusinessId) {
      this.resourceState = resourceState
      this.searchForm.userId = '';
      this.pageName = pageName
      this.revdBusinessId = revdBusinessId
      this.searchForm.resourseId = id
      this.visible = true
      this.$nextTick(() => {
        this.search(1)
        this.getReviewSteps()
      })
    },
    resetting() {
      this.searchForm.userId = '';
      this.search(1)
    },
    search(type) {
      if (type == 1) {
        this.searchForm.current = 1
      }
      this.loading = true
      this.$axios(this.api.dyyg.appraisalrecode, this.searchForm, 'get').then((res) => {
        if (res.status) {
          this.personnelList = res.data.auditVOS
          this.applicantList = res.data.auditVOS
          this.dataList = res.data.appraisalRecodeVO.records
          this.dataList.forEach(item => {
            this.$set(item, 'editShow', false)
          })
          if (this.dataList.length == 0 && this.searchForm.current != 1) {
            this.searchForm.current--
            this.search()
          }
          this.total = parseInt(res.data.appraisalRecodeVO.total)
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    getReviewSteps(){
      this.$axios(this.api.collection.collectionrevdprocessList, {
        dataId: this.searchForm.resourseId,
        revdBusinessId:this.revdBusinessId,
      }, "get").then((data) => {
        if (data && data.status) {
          this.stepsList = data.data
          this.steps = this.stepsList.length
          let firstDta = 0
          //判断是否全是未开始的状态
          this.disabled = this.stepsList[0].complement == 1
          this.stepsList.forEach((item,index)=>{
            if (item.complement == 1 && !firstDta){ //找到当前进行到第几步
              firstDta++
              this.steps = index
              if (this.stepsList[index - 1].revdOperate == 2){ //判断当前进行的步骤是否被驳回
                this.$set(item,'status','wait')
              }
            } else {
              this.$set(item,'status','')
            }
          })
          console.log(this.stepsList)
        }
      });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.search()
    },

    //边打边搜
    remoteMethod(query) {
      this.applicantList = this.personnelList.filter(item => {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
    },

    //编辑
    edit(row) {
      row.editShow = true
    },

    //保存
    saveData(row, num) {
      if (num) {
        this.search()
      } else {
        if (!row.refuseReason) {
          this.$message.error('请输入审核意见！')
          return
        }
        this.$axios(this.api.dyyg.updateById, {
          id: row.id,
          refuseReason: row.refuseReason,
        }, 'put').then(data => {
          if (data && data.status) {
            this.$message.success('修改成功')
            this.search()
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },

    deleteR(row) {
      this.$confirm(`确认删除该审核记录?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.dyyg.removeById + row.id, {}, 'delete').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.search()
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    // 当前页
    currentChangeHandle(val) {
      this.searchForm.current = val
      this.search()
    },
  },
}
</script>

<style scoped>

</style>
